import * as moment from 'moment';
import React, { useRef } from 'react';

import { GoalStep } from '@core/hooks/use-goals';
import { bankHasCMS, getCMSGUID, getPropInSafe } from '@core/libs/utils';
import { SourceType, SyncMarkerInitiators } from '@funds-registers/models';
import { selectSupportedCMS } from '@funds-registers/selectors';
import { selectLastAccountStatementImportsMap } from '@integrations/selectors';
import * as statuses from '@shared/constants/statuses';
import { useTheme } from '@theme';
import { SyncIcon } from '@ui/icons/sync';
import { RaisedButton } from '@ui/raised-button';
import { StatefulTooltip } from '@ui/tooltip';
import { ButtonLayout, ContentLayout, IconLayout, Root, Sign, tooltipStyle, Trigger } from './styled';

export type XSyncMarkerProps = {
	bankName: string;
	connectedToCMS: boolean;
	fundsRegisterID: number;
	initiator?: SyncMarkerInitiators;
	lastAccountStatementImportsMap: ReturnType<typeof selectLastAccountStatementImportsMap>;
	needSyncButtonIfFailed: boolean;
	onAction: (status: string, ID: number) => void;
	selectCMS: (ID: number) => void;
	sourceTypeID?: number;
	supportedCMS: ReturnType<typeof selectSupportedCMS>;
	tooltipPosition: 'right' | 'left' | 'top' | 'bottom';
};

const formateDate = date => moment(date, 'DD-MM-YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm');

const XSyncMarker: React.FC<XSyncMarkerProps> = props => {
	const {
		bankName,
		connectedToCMS,
		fundsRegisterID,
		initiator,
		lastAccountStatementImportsMap,
		needSyncButtonIfFailed,
		onAction,
		selectCMS,
		sourceTypeID,
		supportedCMS,
		tooltipPosition,
	} = props;
	const triggerRef = useRef<HTMLDivElement>(null);
	const { theme } = useTheme();

	const handleClick = e => e.stopPropagation();

	const handleAction = (status: string) => e => {
		if (status === 'CAN_CONNECT') {
			const CMSGUID = getCMSGUID(bankName);
			const CMS = supportedCMS.find(item => {
				return item.SubsystemInstanceGUID === CMSGUID;
			});
			CMS && selectCMS(CMS.ID);
		}
		onAction && onAction(status, fundsRegisterID);
		e.stopPropagation();
	};

	const renderContent = (connectedToCMS: boolean, bankCanConnectToCMS: boolean) => {
		const importStatistics = lastAccountStatementImportsMap[fundsRegisterID];
		const isInitiatorHeader = initiator === SyncMarkerInitiators.ACCOUNT_MENU;

		if (connectedToCMS) {
			if (!importStatistics) {
				return (
					<ContentLayout>
						<Sign>Синхронизация данных не выполнялась</Sign>
						<ButtonLayout>
							<RaisedButton
								appearance='text'
								color='primary'
								goalStep={isInitiatorHeader ? GoalStep.SYNC_START_FROM_HEADER : GoalStep.SYNC_START_FROM_LIST}
								onClick={handleAction('SYNC')}
								size='small'>
								Синхронизировать
							</RaisedButton>
						</ButtonLayout>
					</ContentLayout>
				);
			} else {
				const { ImportStatusCode: status, ImportStartDate: dateStart, ImportFinishDate: dateEnd } = importStatistics;

				if (status === statuses.DUPLICATE_REQUEST) {
					return (
						<ContentLayout>
							<Sign>Процесс синхронизации уже запущен</Sign>
						</ContentLayout>
					);
				}

				if (status === statuses.IN_PROGRESS) {
					return (
						<ContentLayout>
							<Sign>Идет синхронизация</Sign>
							<Sign>{formateDate(dateStart)}</Sign>
						</ContentLayout>
					);
				}

				if (status === statuses.SUCCESS) {
					return (
						<ContentLayout>
							<Sign>Последняя синхронизация</Sign>
							<Sign>{formateDate(dateEnd)}</Sign>
							<ButtonLayout>
								<RaisedButton
									appearance='text'
									color='primary'
									goalStep={isInitiatorHeader ? GoalStep.SYNC_START_FROM_HEADER : GoalStep.SYNC_START_FROM_LIST}
									onClick={handleAction('SYNC')}
									size='small'>
									Синхронизировать
								</RaisedButton>
							</ButtonLayout>
						</ContentLayout>
					);
				}

				if (status === statuses.FAILED || status === statuses.FINISHED_WITH_WARNING) {
					return (
						<ContentLayout>
							<Sign>Последняя синхронизация</Sign>
							<Sign>{formateDate(dateStart)}</Sign>
							<Sign alarm>
								Технические проблемы
								<StatefulTooltip position='bottom'>{importStatistics.Message || 'Неизвестная ошибка'}</StatefulTooltip>
							</Sign>
							{needSyncButtonIfFailed && (
								<ButtonLayout>
									<RaisedButton
										appearance='text'
										color='primary'
										goalStep={isInitiatorHeader ? GoalStep.SYNC_START_FROM_HEADER : GoalStep.SYNC_START_FROM_LIST}
										onClick={handleAction('SYNC')}
										size='small'>
										Синхронизировать
									</RaisedButton>
								</ButtonLayout>
							)}
						</ContentLayout>
					);
				}
			}
		}

		if (bankCanConnectToCMS) {
			return (
				<ContentLayout>
					<Sign>Этот счёт можно подключить к банку</Sign>
					<ButtonLayout>
						<RaisedButton appearance='text' color='primary' size='small' onClick={handleAction('CAN_CONNECT')}>
							Подключить
						</RaisedButton>
					</ButtonLayout>
				</ContentLayout>
			);
		}

		return null;
	};

	const bankCanConnectToCMS = !connectedToCMS && bankHasCMS(bankName);
	const status = getPropInSafe(lastAccountStatementImportsMap[fundsRegisterID], o => o.ImportStatusCode, '');
	const isSyncSourceType = sourceTypeID === SourceType.DIRECT || sourceTypeID === SourceType.EMAIL;

	if ((connectedToCMS || bankCanConnectToCMS) && isSyncSourceType) {
		return (
			<Root>
				<Trigger ref={triggerRef} onClick={handleClick} status={connectedToCMS ? status : 'NOT_CONNECTED'}>
					<IconLayout status={connectedToCMS ? status : 'NOT_CONNECTED'}>
						<SyncIcon color='current' size={14} />
					</IconLayout>
					<StatefulTooltip
						appearance='box'
						position={tooltipPosition}
						style={{ ...tooltipStyle, backgroundColor: theme.palette.canvas, color: theme.palette.text }}>
						{renderContent(connectedToCMS, bankCanConnectToCMS)}
					</StatefulTooltip>
				</Trigger>
			</Root>
		);
	}

	return null;
};

export { XSyncMarker };
